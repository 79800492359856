export function setUser({ commit }, user) {
    commit('storeUser', user)
}
export function setUserKv({ commit }, kv) {
    commit('storeUserKv', kv)
}
export function setPersonId({ commit }, personId) {
    commit('storePersonId', personId)
}
export function setAccountId({ commit }, accountId) {
    commit('storeAccountId', accountId)
}

export function addAppointment({ commit },{ guid, data }) {
    commit('storeAppointment',{guid: guid, data: data })
}
