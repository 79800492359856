export function userAccountId (state) {
    if (state.accountId !== '') {
        return JSON.parse(JSON.stringify(state.accountId))
    } else {
        return undefined
    }
}
export function userPersonId (state) {
    if (state.personId !== '') {
        return JSON.parse(JSON.stringify(state.personId))
    } else {
        return undefined
    }
}
export function user (state) {
    let user = JSON.parse(JSON.stringify(state.user))
    if (user.gender === '' || (user.gender !== '0' && user.gender !== '1' && user.gender !== '2')) {
        user.gender = null
    }
    if (user.type === '' || (user.type !== 'NEW' && user.type !== 'KNOWN')) {
        user.type = null
    }
    if (user.status === '' || (user.status !== 'FIRST' && user.status !== 'REINTRODUCTION')) {
        user.status = null
    }
    if (user.dsgvo === '' || (user.dsgvo !== 'ACCEPTED' && user.dsgvo !== 'NOT_ACCEPTED')) {
        user.dsgvo = 'NOT_ACCEPTED'
    }
    if (user.mail === '' || (user.mail !== 'ACCEPTED' && user.mail !== 'NOT_ACCEPTED')) {
        user.mail = 'NOT_ACCEPTED'
    }
    return user
}
export function userKv (state) {
    if (state.user.kv !== '') {
        return JSON.parse(JSON.stringify(state.user.kv))
    } else {
        return undefined
    }
}

export function appointment (state) {
    return (guid) => {
        if (guid in state.appointments) {
            return JSON.parse(JSON.stringify(state.appointments[guid]))
        } else {
            return undefined
        }
    }
}
