export function person (state) {
    return (id) => {
        if (id in state.persons) {
            return state.persons[id]
        } else {
            return undefined
        }
    }
}

export function persons (state) {
    return JSON.parse(JSON.stringify(Object.values(state.persons)))
}

export function divisions (state) {
    return JSON.parse(JSON.stringify(Object.values(state.divisions)))
}

export function division (state) {
    return (id) => {
        if (id in state.divisions) {
            return state.divisions[id]
        } else {
            return undefined
        }
    }
}

export function personName (state) {
    return (id) => {
        if (id in state.persons) {
            if (state.persons[id].name) {
                return state.persons[id].name
            }
        } else {
            return undefined
        }
    }
}

export function appointmentDetail (state) {
    return JSON.parse(JSON.stringify(state.appointmentDetail))
}

export function locations (state) {
    if (state.locations !== undefined && state.locations !== null) {
        return state.locations
    } else {
        return undefined
    }
}

export function location (state) {
    return (id) => {
        if (id in state.locations) {
            return state.locations[id]
        } else {
            return undefined
        }
    }
}

export function filter (state) {
    if (state.filter !== undefined && state.filter !== null) {
        return state.filter
    } else {
        return undefined
    }
}

export function filterByKey (state) {
    return (key) => {
        if (key in state.filter) {
            return state.filter[key]
        } else {
            return undefined
        }
    }
}
