import Fetcher from '../../helper/fetcher.js'
import { uuid } from 'vue-uuid'

export function addVaccinationRequest({ commit }, requestData) {
    return new Promise((resolve, reject) => {
    if (requestData !== undefined && requestData !== null) {
        const data = {
            vaccid: requestData.id !== undefined && requestData.id !== null ? requestData.id : undefined,
            title: requestData.title,
            lastname: requestData.lastname,
            firstname: requestData.firstname,
            birthdate: requestData.birthday,
            gender: requestData.gender,
            privat: requestData.kv === 'PKV' ? 1 : 0,
            neu: requestData.status === 'NEW' ? 1 : 0,
            phone: requestData.phone,
            notes: requestData.note.slice(0,254),
            email: requestData.email,
            request_id: uuid.v4(),
            account_id:  undefined,
            person_id: undefined,
            diseases: requestData.diseases.slice(0,254),
            familyDoc: requestData.familyDoc,
            lastVisit: requestData.lastVisit,
            behandler: (requestData.behandler!==undefined?requestData.behandler:"keiner")
        }

        const fetchObject = Fetcher.buildVaccinationRequestURLAndOptions(window.vaccination.url, data)
        fetch(fetchObject.url, fetchObject.options)
            .then(result => {
                this.httpStatus = result.status
                this.resultObject = result
                if (result.ok || result.status === 201 || result.status === 202) {
                    return result.json()
                } else {
                    switch (result.status) {
                        case 412: // Validation Error
                            throw {error: 412, text: 'Bei der Prüfung Ihrer Daten ist es zu einem Fehler gekommen'}
                        default:
                            throw result.status
                    }
                }
            })
            .then(result => {
                if (result !== undefined && result !== null &&
                    result.state !== undefined && result.state !== null && result.state === 1 &&
                    result.vaccid !== undefined && result.vaccid !== null) {
                    requestData.id = result.vaccid
                    commit('storeVaccinationRequest', requestData)
                    resolve()
                } else {
                    throw { error: 42, text: result }
                }
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })

    } else {
        reject('No requestData')
    }
    })
}
export function deleteVaccinationRequest({ commit }, requestId) {
    return new Promise((resolve, reject) => {
        if (requestId !== undefined && requestId !== null){
                let fetchObject = Fetcher.buildCancelVaccinationRequestURLAndOptions(window.vaccination.url, requestId)
                fetch(fetchObject.url, fetchObject.options)
                    .then(result => {
                        this.httpStatus = result.status
                        this.resultObject = result
                        if (result.ok || result.status === 201 || result.status === 202) {
                            return result.json()
                        } else {
                            switch (result.status) {
                                case 412: // Validation Error
                                    throw {error: 412, text: 'Bei der Prüfung Ihrer Daten ist es zu einem Fehler gekommen'}
                                default:
                                    throw result.status
                            }
                        }
                    })
                    .then(result => {
                        if (result !== undefined && result !== null &&
                            result.state !== undefined && result.state !== null && result.state === 2 &&
                            result.vaccid !== undefined && result.vaccid !== null) {
                            commit('removeVaccinationRequest', result.vaccid)
                            resolve()
                        } else {
                            throw { error: 42, text: result }
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
        } else {
            reject('No RequestID given')
        }
    })
}

export function saveTempRequestData({commit}, requestData){
    if (requestData !== undefined && requestData !== null) {
        commit('storeTempVaccinationRequest', requestData)
    }
}

export function deleteTempRequestData({commit}) {
    commit('removeTempVaccinationRequest')
}
