<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import './assets/custom.scss'
import {mapActions} from "vuex";

export default {
  name: 'App',
  mounted() {
    window.onlinetermin.locations.forEach(location => {
      this.addLocation(location)
    })
    window.onlinetermin.filter.forEach(filter => {
      this.addFilter(filter)
    })
  },
  methods: {
    ...mapActions('calender', {
      addLocation: 'addLocation',
      addFilter: 'addFilter'
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
