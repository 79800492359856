export function addPerson({ commit }, person) {
    if (person !== undefined && person !== null && person.id !== undefined && person.id !== null) {
        commit('storePerson', person)
    }
}
export function addDivision({ commit }, division) {
    if (division !== undefined && division !== null && division.id !== undefined && division.id !== null){
        commit('storeDivision', division)
    }
}

export function setAppointmentDetail({ commit }, detail) {
    if (detail !== undefined && detail !== null) {
        commit('storeAppointmentDetail', detail)
    }
}

export function addLocation( {commit}, location ) {
    if (location !== undefined && location !== null) {
        commit('storeLocation', location)
    }
}

export function addFilter( {commit}, filter ) {
    if (filter !== undefined && filter !== null) {
        commit('storeFilter', filter)
    }
}
