export function listVaccinationRequests (state) {
    return JSON.parse(JSON.stringify(state.vaccinationRequests))
}

export function requestById (state) {
    return (id) => {
      const index = state.vaccinationRequests.findIndex(entry => entry.id === id)
      if (index !== undefined && index !== null && index !== -1){
          return JSON.parse(JSON.stringify(state.vaccinationRequests[index]))
      } else {
          return undefined
      }
    }
}

export function savedTempRequestData (state) {
    if (state.tempRequestData !== undefined && state.tempRequestData !== null && !state.tempRequestData.empty) {
        return JSON.parse(JSON.stringify(state.tempRequestData.requestData))
    } else {
        return null
    }
}
