import Vue from 'vue'
import * as VueGoogleMaps from 'gmap-vue'

Vue.use(VueGoogleMaps, {
    load: {
        key: window.onlinetermin.gmapApi,
        region: 'DE',
        language: 'DE'
    },
    installComponents: true
})
