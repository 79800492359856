import Vue from 'vue'

import { BootstrapVue, BIcon, BIconCaretLeftFill, BIconCaretRightFill, BIconPersonCircle, BIconFilterLeft, BIconSearch, BIconSortAlphaUp, BIconSortAlphaDown, BIconXCircle, BIconPencil, BIconEye } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconCaretLeftFill', BIconCaretLeftFill)
Vue.component('BIconCaretRightFill', BIconCaretRightFill)
Vue.component('BIconPersonCircle', BIconPersonCircle)
Vue.component('BIconFilterLeft', BIconFilterLeft)
Vue.component('BIconSearch', BIconSearch)
Vue.component('BIconSortAlphaUp', BIconSortAlphaUp)
Vue.component('BIconSortAlphaDown', BIconSortAlphaDown)
Vue.component('BIconXCircle', BIconXCircle)
Vue.component('BIconPencil', BIconPencil)
Vue.component('BIconEye', BIconEye)
