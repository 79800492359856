export function storeUser (state, user){
    for (const [key, value] of Object.entries(user)) { //eslint-disable-line no-unused-vars
        state.user[key] = value
    }
}
export function storeUserKv (state, kv){
    state.user['kv'] = kv
}
export function storePersonId (state, value){
    state.personId = value
}
export function storeAccountId (state, value){
    state.accountId = value
}

export function storeAppointment (state, {guid, data }) {
    state.appointments[guid] = data
}
