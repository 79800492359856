export function storeVaccinationRequest (state, request){
    if (request.id !== undefined && request.id !== null) {
        const index = state.vaccinationRequests.findIndex(entry => entry.id === request.id)
        if (index > -1) {
            state.vaccinationRequests.splice(index,1, request)
        } else {
            state.vaccinationRequests.push(request)
        }
    } else {
        state.vaccinationRequests.push(request)
    }
}

export function removeVaccinationRequest (state, requestId){
    const index = state.vaccinationRequests.findIndex(entry => entry.id === requestId)
    state.vaccinationRequests.splice(index, 1)
}

export function storeTempVaccinationRequest (state, requestData) {
    state.tempRequestData.empty = false
    state.tempRequestData.requestData = requestData
}

export function removeTempVaccinationRequest (state){
    state.tempRequestData.empty = true
    state.tempRequestData.requestData = {
        requestData: {
            status: null,
            kv: null,
            gender: null,
            title: '',
            firstname: '',
            lastname: '',
            birthday: '',
            phone: '',
            email: '',
            emailCheck: '',
            note: '',
            dsgvo: 'NOT_ACCEPTED',
            mail: 'NOT_ACCEPTED',
            terms: 'NOT_ACCEPTED',
            covid19: 'NOT_ACCEPTED',
            diseases: '',
            familyDoc: null,
            lastVisit: null
        },
    }
}
