export default function () {
    return {
        user: {
            status: null,
            kv: '',
            gender: null,
            title: '',
            firstname: '',
            lastname: '',
            birthday: '',
            phone: '',
            email: '',
            type: null,
            dsgvo: false,
            mail: false,
            note: ''
        },
        personId: '',
        accountId: '',
        appointments: {}
    }
}

