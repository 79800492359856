import { Client } from '@stomp/stompjs'
import {uuid} from "vue-uuid";

export default {
    install(Vue, options) {
        let connected = false
        let subscribes = []
        const clientID = 'Wubbel' + uuid.v4
        let client
        if (options.url !== undefined) {
            client = new Client({
                brokerURL: options.url,
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000
            })

            if (client !== undefined) {
                client.onConnect = () => {
                    connected = true
                    if (Array.isArray(subscribes) && subscribes.length > 0) {
                        subscribes.forEach((subscribe) => {
                            client.subscribe(subscribe.topic, subscribe.callback, {receipt: clientID})
                        })
                        subscribes.length = 0
                        subscribes = []
                    }
                }
                client.onStompError = (frame) => {
                    console.error('Broker reported error: ', frame.headers['message']);
                    console.error('Additional details: ', frame.body);
                }
                client.activate()
            }
        }

        Vue.prototype.$stomp = {}

        Vue.prototype.$stomp.onConnect = function (callback) {
            if (client !== undefined) {
                client.onConnect = callback
            }
        }
        Vue.prototype.$stomp.deactivate = function () {
            if (client !== undefined) {
                client.deactivate()
            }
        }

        Vue.prototype.$stomp.publish = function (destination, body) {
            if (connected && client !== undefined) {
                client.publish({
                    destination: destination,
                    body: JSON.stringify(body),
                    headers: {receipt: clientID}
                })
            }
        }
        Vue.prototype.$stomp.subscribe = function (topic, callback) {
            if (connected && client !== undefined) {
                return client.subscribe(topic, callback, {receipt: clientID})
            } else {
                subscribes.push({
                    topic: topic,
                    callback: callback
                })
            }
        }
    }
}
