import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import calender from './module-calender'
import user from './module-user'
import vaccination from './module-vaccination'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        calender,
        user,
        vaccination
    },
    strict: process.env.DEV,
    plugins: [createPersistedState()],
})

store.$app = Vue

export default store
