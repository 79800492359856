class Fetcher {

    buildBaseURLAndOptions (baseUrl, type, formBody, filter) {
        const filterString = this.filterString(filter)
        return {
            url: `${baseUrl}/base${filterString.length > 0 ? '?' + filterString: ''}`,
            options: {
                credentials: 'include',
                method: type,
                body: type === 'POST' ? formBody : undefined,
                headers: type === 'POST' ? { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' } : undefined
            }
        }
    }

    buildPersonURLAndOptions(baseUrl, personId, date, filter) {
        const filterString = this.filterString(filter)
        return {
            url: `${baseUrl}/person/${personId}/week/${date}${filterString.length > 0 ? '?' + filterString: ''}`,
            options: {
                method: 'GET',
                credentials: 'include'
            }
        }
    }

    buildDivisionURLAndOptions(baseUrl, divisionId, date, filter) {
        const filterString = this.filterString(filter)
        return {
            url: `${baseUrl}/division/${divisionId}/week/${date}${filterString.length > 0 ? '?' + filterString: ''}`,
            options: {
                method: 'GET',
                credentials: 'include'
            }
        }
    }

    buildAppointmentRequestURLAndOptions(baseUrl, data) {
        return {
            url: `${baseUrl}/booking`,
            options: {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            }
        }
    }

    buildVaccinationRequestURLAndOptions(baseUrl, data) {
        let method = 'POST'
        let url = `${baseUrl}/vaccination`
        if (data !== undefined && data !== null && data.vaccid !== undefined && data.vaccid !== null) {
            method = 'PUT'
            url = `${baseUrl}/vaccination/${data.vaccid}`
            data.vaccid = undefined
        }
        return {
            url: url,
            options: {
                method: method,
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            }
        }
    }

    buildCancelVaccinationRequestURLAndOptions(baseUrl, guid) {
        return {
            url: `${baseUrl}/vaccination/${guid}`,
            options: {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            }
        }
    }


    buildCheckRequestURLAndOptions(baseUrl, guid) {
        return {
            url: `${baseUrl}/booking/${guid}`,
            options: {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            }
        }
    }

    buildCancelRequestURLAndOptions(baseUrl, guid) {
        return {
            url: `${baseUrl}/booking/${guid}`,
            options: {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            }
        }
    }

    buildPersonsRequestURLAndOptions(baseUrl, filter) {
        let filterString = ''
        filter.forEach(filterEntry => {
            if (filterString.length === 0) {
                filterString += `${filterEntry.key}=${filterEntry.value}`
            } else {
                filterString += `;${filterEntry.key}=${filterEntry.value}`
            }
        })
        return {
            url: `${baseUrl}/persons${filterString.length > 0 ? '?' + filterString: ''}`,
            options: {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            }
        }
    }

    buildDivisionsRequestURLAndOptions(baseUrl, filter) {
        let filterString = ''
        filter.forEach(filterEntry => {
            if (filterString.length === 0) {
                filterString += `${filterEntry.key}=${filterEntry.value}`
            } else {
                filterString += `;${filterEntry.key}=${filterEntry.value}`
            }
        })
        return {
            url: `${baseUrl}/divisions${filterString.length > 0 ? '?' + filterString: ''}`,
            options: {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            }
        }
    }

    filterString (filter) {
        let filterString = ''
        if (Array.isArray(filter)) {
            filter.forEach(filterEntry => {
                if (filterString.length === 0) {
                    filterString += `${filterEntry.key}=${filterEntry.value}`
                } else {
                    filterString += `&${filterEntry.key}=${filterEntry.value}`
                }
            })
        } else if (typeof filter === 'string'){
            filterString += `filter=${filter}`
        }

        return filterString
    }

}

export default new Fetcher()
