const routes = [
    {
        path: '/',
        component: () => {
            if (window.onlinetermin.disabled){
                return import('../pages/maintenance-mode.vue')
            } else {
                return import('../layout/main-layout.vue')
            }
        },
        children: [
            { name: 'index', path: '', component: () => import('../pages/index.vue') },
            { name: 'welcome', path: 'welcome', component: () => import('../pages/welcome.vue') },
            { name: 'terms', path: 'terms', component: () => import('../pages/terms.vue') },
            { name: 'covid', path: 'covid', component: () => import('../pages/covid.vue') },
            { name: 'dsgvo', path: 'dsgvo', component: () => import('../pages/dsgvo.vue') },
        ]
    },
    {
        path: '/mobile',
        component: () => {
            if (window.onlinetermin.disabled){
                return import('../pages/maintenance-mode.vue')
            } else {
                return import('../layout/main-layout.vue')
            }
        },
        children: [
            { name: 'mobile', path: '', component: () => import('../pages/welcome.vue') },
            { name: 'mobile-main', path: 'main', component: () => import('../pages/mobile-new.vue') },
            { name: 'mobile-detail-person', path: 'detail/person/:id/:filter/:locationId', component: () => import('../pages/mobile/person-detail.vue')},
            { name: 'mobile-detail-division', path: 'detail/division/:id/:filter/:locationId', component: () => import('../pages/mobile/division-detail.vue')},
            { name: 'mobile-new-appointment', path: 'new/appointment', component: () => import('../pages/mobile/make-appointment.vue')}
        ]
    },
    {
        path: '/desktop',
        component: () => {
            if (window.onlinetermin.disabled){
                return import('../pages/maintenance-mode.vue')
            } else {
                return import('../layout/main-layout.vue')
            }
        },
        children: [
            { name: 'welcome', path: '', component: () => import('../pages/welcome.vue') },
            { name: 'desktop-main', path: 'main', component: () => import('../pages/desktop-new.vue') }
        ]
    },
    {
        path: '/appointment/:appointmentId',
        component: () => {
            if (window.onlinetermin.disabled){
                return import('../pages/maintenance-mode.vue')
            } else {
                return import('../pages/appointment.vue')
            }
        }
    },
    {
        path: '/vaccination',
        component: () => {
            if (window.vaccination.disabled){
                return import('../pages/maintenance-mode.vue')
            } else {
                return import('../layout/vaccination-layout.vue')
            }
        },
        children: [
            { name: 'vaccination-list', path:'', component: () => import('../pages/vaccination/list.vue') },
            { name: 'vaccination-form', path:'form', component: () => import('../pages/vaccination/form.vue') },
            { name: 'vaccination-form-edit', path:'form/:id', component: () => import('../pages/vaccination/form.vue') },
            { name: 'vaccination-after-form', path:'thankyou', component: () => import('../pages/vaccination/after_form.vue') },
            { name: 'vaccination-cancel', path:'cancel/:id', component: () => import('../pages/vaccination/cancel.vue') },
            { name: 'vaccination-success', path:'success', component: () => import('../pages/vaccination/success.vue') },
            { name: 'vaccination-error', path:'error', component: () => import('../pages/vaccination/error.vue') },
            { name: 'vaccination-terms', path:'terms', component: () => import('../pages/vaccination/terms.vue') },
            { name: 'vaccination-covid', path:'covid', component: () => import('../pages/vaccination/covid.vue') },
            { name: 'vaccination-dsgvo', path:'dsgvo', component: () => import('../pages/vaccination/dsgvo.vue') },
        ]
    }
]

if (process.env.MODE !== 'ssr') {
    routes.push({
        path: '*',
        component: () => import('../pages/Error404.vue')
    })
}

export default routes
