export function storePerson (state, person){
    const personID = person.id
    state.persons[personID] = person
}
export function storeDivision (state, division){
    const divisionID = division.id
    state.divisions[divisionID] = division
}

export function storeAppointmentDetail (state, detail) {
    state.appointmentDetail = detail
}

export function storeLocation (state, location) {
    state.locations[location.locationId] = location
}

export function storeFilter (state, filter) {
    state.filter[filter.key] = filter
}
